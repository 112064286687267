import { Button, Center, Icon, StackDivider, VStack } from '@chakra-ui/react';

import { useTranslation, useWpInfiniteItems } from 'hooks';
import { memo } from 'react';
import { getAcfBlockFetchData } from 'utls';

import dynamic from 'next/dynamic';
import BlogCard from 'components/blogs/BlogCard';
import { ArrowLeft } from 'iconsax-react';
import { ItemSkeleton } from 'components/shared/Skeleton';
const PostItem = dynamic(() => import('../../shared/PostItem'));

const WideBlogCards = props => {
  const { attrs, blockName } = props;
  const { t } = useTranslation();

  if (!attrs) {
    return null;
  }

  const { data } = attrs;
  const { name, params } = getAcfBlockFetchData({
    blockName,
    ...data
  });

  const { items, hasMore, isLoading, loadMore } = useWpInfiniteItems(
    name,
    params
  );

  if (items?.length === 0) return null;

  const { enable_pagination } = data;

  const hasLoadMore = hasMore && enable_pagination === '1';

  return (
    <>
      <VStack
        w='full'
        align={'stretch'}
        spacing={4}
        divider={<StackDivider borderColor='gray.200' />}
        borderBottomWidth={1}
        borderBottomColor='gray.200'
        py='4'
        px='4'
      >
        {Array.isArray(items) &&
          items?.length > 0 &&
          items?.map(item => (
            <BlogCard
              key={item?.id}
              {...item}
              hasAuthor
              isEditorChoice={true}
            />
          ))}

        {isLoading &&
          Array(10)
            .fill(0)
            .map((_, i) => <ItemSkeleton key={i} />)}
      </VStack>
      {hasLoadMore && (
        <Center>
          <Button
            w='full'
            rightIcon={<Icon as={ArrowLeft} />}
            onClick={loadMore}
            isLoading={isLoading}
            isDisabled={isLoading}
            color='#292D32'
            bg='#F6F7F7'
          >
            {t('general.more')}
          </Button>
        </Center>
      )}
    </>
  );
};

export default memo(WideBlogCards);
