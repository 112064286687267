import { Box, Heading, HStack, LinkBox, Stack, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';

import { Image } from 'components/shared';
import { UserCard } from 'components/users';
import { NextChakraLinkOverlay } from 'components/wrappers';
import { useBookmark } from 'hooks';
import type { User } from 'types/users';
import { htmlParser } from 'utls';

const BlogCard = ({
  id,
  isEditorChoice,
  hasNoImage,
  title,
  author,
  link,
  author_thumb,
  excerpt,
  _embedded,
  thumbnail,
  authorOrder,
  truncate,
  author_link
}) => {
  const featuredMedia = _embedded?.['wp:featuredmedia']?.[0];
  const blogAuthor = _embedded?.['author']?.[0] as User;
  const [isBooked, setIsBooked] = useState(null);
  const callBack = ({ data }) => {
    setIsBooked(data?.is_bookmarked);
    // refetchBookMarks();
  };
  const { mutateBookMark, isLoading } = useBookmark(id, callBack);

  return (
    <LinkBox as='article' w='full' py='1'>
      <VStack spacing={4} align={'stretch'}>
        {authorOrder && (
          <Box>
            <UserCard
              {...blogAuthor}
              blogAuthor={blogAuthor}
              author={author}
              author_thumb={author_thumb}
              author_link={author_link}
            />
          </Box>
        )}
        <NextChakraLinkOverlay href={link}>
          <Image
            image={{ url: thumbnail }}
            ratio={16 / 9}
            rounded='md'
            boxShadow={'xl'}
            sizes={'(min-width: 62em) 15vw, 30vw'}
            blog={true}
          />
        </NextChakraLinkOverlay>

        <HStack
          pos='relative'
          gap={4}
          width='full'
          justify='space-between'
          align='start'
        >
          <Stack gap={0} flex={1}>
            {isEditorChoice && (
              <Box order={isEditorChoice ? '3' : ''} mb='0' mt='0'>
                <UserCard
                  {...blogAuthor}
                  blogAuthor={blogAuthor}
                  author={author}
                  author_thumb={author_thumb}
                />
              </Box>
            )}

            <Stack gap={2}>
              <Heading
                as='h1'
                fontSize={'lg'}
                order={isEditorChoice ? '1' : ''}
                // noOfLines={1}
                noOfLines={truncate ? 1 : ''}
              >
                <NextChakraLinkOverlay href={link}>
                  {title?.rendered ? htmlParser(title?.rendered) : title}
                </NextChakraLinkOverlay>
              </Heading>
              {excerpt && (
                <Box
                  noOfLines={truncate ? 3 : ''}
                  order={isEditorChoice ? '2' : ''}
                  lineHeight={'1.8 !important'}
                  color={isEditorChoice ? 'dark.300' : ''}
                  dangerouslySetInnerHTML={{
                    __html: excerpt?.rendered ?? excerpt
                  }}
                />
              )}
            </Stack>
          </Stack>

          <Stack width='64' flex={1}>
            {!hasNoImage && featuredMedia && (
              <NextChakraLinkOverlay href={link}>
                <Image
                  image={featuredMedia}
                  ratio={16 / 9}
                  rounded='md'
                  boxShadow={'xl'}
                  sizes={'(min-width: 62em) 15vw, 30vw'}
                  blog={true}
                />
              </NextChakraLinkOverlay>
            )}
          </Stack>
        </HStack>
      </VStack>
    </LinkBox>
  );
};

export default BlogCard;
